//targets
const sidebar = document.querySelector('.nav__sidebar');
const sidebarBtn = document.querySelector('.nav__sidebar-btn');
const navItms = document.querySelectorAll('.nav__sidebar-item');
//functions
const changeVisibility = ()=>{
    sidebar.classList.toggle('show');
}

//events
sidebarBtn.addEventListener('click', changeVisibility);
navItms.forEach(item => {
    item.addEventListener('click', changeVisibility);
});